import React, { useRef, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import kebabCase from 'lodash.kebabcase'

import { Layout } from '../components/common'
import  {
    Container,
    PostContainer,
    NavigationContainer,
    QuickNavigationContainer,
    NavigationHeading,
    NavigationLink,
    Info,
    Title,
    Date,
    Post,
    Category,
    Tag,
    TagList,
    BeginnerLevel,
    IntermediateLevel,
    ExpertLevel
} from '../pageStyles/BlogStyle';
import { TelegramChannel } from '../components/common/Promo';
import { AuthorBio } from '../components/blog/Author';
import { ShareButtons } from '../components/common/ShareButtons';
import { SubHeading } from '../pageStyles';


const PostPage = ({ data, location }) => {

    const {
        markdownRemark: {
            frontmatter: {
                title,
                excerpt,
                date,
                author,
                ogimage,
                tags,
                categories,
                level,
            },
            fields : {
                slug,
            },
            html
        }
    } = data;

    const post = useRef(null);

    const [ headings, setHeadings ] = useState(null);

    useEffect(() => {
        const postDiv = post.current;

        const headingsRaw = postDiv.querySelectorAll('h2');
        const headings = [...headingsRaw].map(heading => {
            let indentLevel = 0;
            const tagName = heading.tagName;

            if(tagName === 'H3'){
                indentLevel = 1
            } else if(tagName === 'H4'){
                indentLevel = 2
            } else if(tagName === 'H5'){
                indentLevel = 3
            } else if(tagName === 'H6'){
                indentLevel = 4
            }

            return {
                text: heading.innerText, 
                heading,
                indentLevel
            }
        });

        setHeadings(headings)
    }, [])

    const handleNavigationClick = element => {
        element.scrollIntoView({behavior: "smooth", block: "start"});
    }

    const getOgSlug = slug => {
        const slugSplit = slug.split('/')
        const ogSlug = slugSplit[slugSplit.length - 2]

        return ogSlug;
    }

    const ogImage = ogimage || '/images/ogimage.png';

    const renderLevel = (param) => {
        switch(param[0]) {
            case 'Beginner':
                return <BeginnerLevel href={'/beginner'}>Principiante</BeginnerLevel>;
            case 'Intermedio':
                return <IntermediateLevel href={'/intermedio'}>Intermedio</IntermediateLevel>;
            case 'Esperto':
                return <ExpertLevel >Esperto</ExpertLevel>;
            default:
                return null;
        }
    }
    

    return (
        <>
            <Helmet>
                <title>{title} | NorthFPV</title>
                <meta name="description" content={excerpt}/>
                <meta name="author" content="NorthFPV" />
                
                <meta property="og:type" content="article" />
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={excerpt}/>
                <meta property="og:url" content={`http://northfpv.com/${getOgSlug(slug)}`}/>
                <meta property="og:image" content={ogImage}/>

                <meta name="twitter:title" content={title}/>
                <meta name="twitter:description" content={excerpt}/>
                <meta name="twitter:image" content={ogImage}/>
                <meta name="twitter:card" content="summary_large_image"/>

          </Helmet>
            <Layout promotion={<TelegramChannel/>}>

            <Container>
                <NavigationContainer>
                    <QuickNavigationContainer>
                        <NavigationHeading>
                            Indice
                        </NavigationHeading>
                        {headings && headings.map((heading, index) => (
                            <NavigationLink
                                onClick={() => handleNavigationClick(heading.heading)}
                                indentLevel={heading.indentLevel}
                                key={index}
                            >
                                {heading.text}
                            </NavigationLink>
                        ))}
                        { tags && <>
                            <NavigationHeading>
                                Tags
                            </NavigationHeading>
                            <TagList>
                                {tags.map(tag => 
                                    <Tag href={`/tags/${kebabCase(tag)}`}>#{tag}</Tag>
                                )}
                            </TagList>
                        </>}
                    </QuickNavigationContainer>
                </NavigationContainer>
                <PostContainer>
                    <Info>
                        <Date>
                            {date} &nbsp;&middot;&nbsp; @{author}
                        </Date>
                        <Title>
                            {title}
                        </Title>
                        { level && renderLevel(level) }
                        {categories && categories.map(cat => 
                            <Category href={`/categoria/${kebabCase(cat)}`}>
                                {cat}
                            </Category>
                        )}
                    </Info>
                    <Post 
                        dangerouslySetInnerHTML={{ __html: `<div>${html}</div>` }}
                        as="div"
                        ref={post}
                    />
                    <AuthorBio author={author}/>
                    <ShareButtons url={`http://northfpv.com/${getOgSlug(slug)}`} title={title} />
                </PostContainer>
            </Container>
            </Layout>
        </>
    )
}

export default PostPage;

export const postQuery = graphql`
query ($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
        id
        html
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "DD MMMM YYYY", locale: "it")
          ogimage
          excerpt
          author
          ogimage
          tags
          categories
          level
        }
      }
  }  
`
