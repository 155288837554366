import styled from 'styled-components';
import { colors, fonts, media} from '../utils';

export const Container = styled.div`
    display: flex;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 4vw;
`;

export const NavigationContainer = styled.div`

    ${media.large`
        height: 100%;
        padding-right: 16px;
        position: sticky;
        top: 48px;
        width: calc(300px - 24px);
        z-index: 60;
    `}
`;

export const QuickNavigationContainer = styled.div`
    display: none;

    ${media.large`
        display: block;
        max-height: 100vh;
        overflow-y: auto;
        height: 100%;
    `}
`;

export const NavigationHeading = styled(fonts.SubHeading)`
    color: ${colors.darkGray};
    margin-bottom: 16px;
    text-transform: uppercase;
`;

export const NavigationLink = styled.a`
    color: ${colors.personality};
    cursor: pointer;
    display: block;
    margin-bottom: 8px;
    margin-left: ${props => props.indentLevel * 8}px;

`;

export const PostContainer = styled.div`
    max-width: 700px;
    padding: 0 16px;
    width: 100%;

    ${media.medium`
        margin: 0 auto;
    `}

    img {
        display: block;
        margin:  auto;
        padding: 24px 0;
        width: 100%;
    }
`;

export const Info = styled.div`
    border-bottom: 1px solid ${colors.lightGray};
    padding-bottom: 16px;
    margin-bottom: 16px;
`;


export const Title = styled(fonts.Billboard)`
    color: ${colors.black};
    margin: 8px 0 0 0;
`;

export const Date = styled(fonts.Eyebrow)`
    color: ${colors.darkGray};
    margin: 0;
`;


export const Post = styled(fonts.Body)`
    color: ${colors.black};

    a {
        color: ${colors.personality};
    }

    p {
        line-height: 30px;
    }


    blockquote {
        border-left: 5px solid ${colors.lightGray};
        margin-left: 0;
        padding-left: 2em;
    }
    
`;

export const Heading = styled(fonts.SubHeading)`
    border-radius: 4px 4px 0 0;
    color: ${colors.black};
    margin: 0 0 8px 0;
`;

export const Paragraph = styled(fonts.Body)`
    color: ${colors.darkGray};
`;

export const Label = styled.label`
    color: ${colors.darkGray};
    font-size: 14px;
`;


export const Button = styled.button`
    appearance: none;
    background-color: ${colors.white};
    border: 1px solid ${colors.lightGray};
    border-left: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    padding: 12px 16px;
    transition: background-color 0.3s ease-in-out;

    &:hover, &:focus {
        background-color: ${colors.lightGray};
        outline: none;
    }
`;

export const Tag = styled.a`
    margin: 0.2rem 0.3rem;
    color: ${colors.personality};
`;

export const TagList = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Level = styled.a`
    display:inline-block;
    padding: 0.1em 0.7em;
    border-radius: 2rem;
    margin: 2rem 1rem 0 0;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: bold;
    font-size: medium;
    background-color: ${colors.personality};
    color: ${colors.white} !important;
    text-align: center;
    transition: all 0.2s;

    cursor: pointer;

    &:hover {
        font-weight: bolder;
        text-decoration: none;
    }
`;

export const Category = styled(Level)`
    background-color: ${colors.personality};
`;

export const BeginnerLevel = styled(Level)`
    background-color: #00c928;
`;

export const IntermediateLevel = styled(Level)`
    background-color: #999999;
`;

export const ExpertLevel = styled(Level)`
    background-color: #c90700;
`;