import styled from 'styled-components';
import { colors, fonts, media } from '../../../utils';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2rem auto;
`;

export const SubHeading = styled(fonts.SubHeading)`
    text-align: center;
`;

export const Share = styled.div`
    margin: 0 1rem;
`;