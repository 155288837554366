import React from 'react'

import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon
} from 'react-share'

import {
    Container,
    Share,
    SubHeading
} from './styled';

export const ShareButtons = ({url, title}) => {

    return(<>
        <SubHeading>
            Condividi l'articolo
        </SubHeading>
        <Container>
            <Share>
                <FacebookShareButton url={url} >
                        <FacebookIcon  size={40} round={true}/>
                </FacebookShareButton>
            </Share>
            <Share>
                <RedditShareButton url={url} title={title} >
                    <RedditIcon  size={40} round={true} />
                </RedditShareButton>
            </Share>
            <Share>
                <WhatsappShareButton url={url} title={title}>
                    <WhatsappIcon  size={40} round={true}/>
                </WhatsappShareButton>
            </Share>
        </Container>
        </>
      )

}