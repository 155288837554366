import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import {
    Container,
    Avatar,
    Content,
    Heading,
    Paragraph,
    Button
} from './styled';


export const AuthorBio = ({author}) => {

    const data = require(`../../../data/authors/${author}.json`);

    return (
        <Container>
            <Avatar src={data.image}/>
            <Content>
                <Link href={`/autori/${data.slug}`}>
                    <Heading>
                        {data.name}
                    </Heading>
                </Link>
                <Paragraph>
                    {data.description}
                </Paragraph>
                {data.url ? <Button href={data.url} target="_blank">
                    {data.label}
                </Button>
                : null}
            </Content>
        </Container>
    );
}

